import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "winnerField", "loserField" ]
  
  getReversedLastNameFromString(someString) {
    const spaceChar = " "
    const parts = someString.split(spaceChar)
    const wordCount = parts.length
    
    if (wordCount === 1) {
      return someString
    }
    
    const lastWordIdx = (wordCount - 1)
    const lastWord = parts[lastWordIdx]
    const penultimateWordIx = (wordCount - 2)
    const wordsThroughPenult = parts.slice(0, lastWordIdx)
    const givenName = wordsThroughPenult.join(spaceChar)
    
    return `${lastWord}, ${givenName}`
  }
  
  processString(someString) {
    if (this.stringAppearsToBeTwoJoinedFullNames(someString)) {
      const separateNames = this.getSeparateNamesFromJoinedNamesString(someString)
      
      const firstNameReversed = this.getReversedLastNameFromString(separateNames[0])
      const lastNameReversed = this.getReversedLastNameFromString(separateNames[1])

      return `${firstNameReversed} & ${lastNameReversed}`
    } else {
      const sortNamesFromSingleLastNameWithDoubleName = this.getSortNamesFromDoubleNameWithSingleLastName(someString)
      
      if (sortNamesFromSingleLastNameWithDoubleName == null) {
        return this.getReversedLastNameFromString(someString)
      }
      
      return sortNamesFromSingleLastNameWithDoubleName
    }
  }
  
  getSeparateNamesFromJoinedNamesString(someString) {
    return someString.split(" & ")
  }
  
  getSortNamesFromDoubleNameWithSingleLastName(someString) {
    const ampersandedParts = someString.split(" & ")
    
    if (ampersandedParts.length == 2) {
      const firstSubname = ampersandedParts[0]
      const lastSubname = ampersandedParts[1]
      
      const firstSubnameParts = firstSubname.split(" ")
      const lastSubnameParts = lastSubname.split(" ")
      
      if ((firstSubnameParts.length === 1) && (lastSubnameParts.length === 2)) {
        const firstPersonsFirstName = firstSubname
        const secondPersonsFirstName = lastSubnameParts[0]
        const lastName = lastSubnameParts[1]
        
        return `${lastName}, ${firstPersonsFirstName} & ${lastName}, ${secondPersonsFirstName}`
      }
      
      return null
    }
    
    return null
  }
  
  stringAppearsToBeTwoJoinedFullNames(someString) {
    const splits = someString.split(" & ")
    const ampersandCount = (splits.length - 1)
    
    if (ampersandCount === 1) {
      let foundViolation = false
      let i = 0
      
      while ((i < splits.length) && !foundViolation) {
        const potentialName = splits[i]
        const subparts = potentialName.split(" ")
        
        if (subparts.length !== 2) {
          foundViolation = true
        }
        
        i++
      }
      
      return (!foundViolation)
    }
    
    return false
  }
  
  countSubstringAppearancesInString(substring, someString) {
    const splits = someString.split(substring)
    return (splits.length - 1)
  }
  
  update() {
    const winnerFieldValue = this.winnerFieldTarget.value
    const newLoserFieldValue = this.processString(winnerFieldValue)
    this.loserFieldTarget.value = newLoserFieldValue
  }
}